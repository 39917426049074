import React from 'react'

import Logo from "../assets/img/Logo.jpeg";

const Imagem = () => {
    return (
        <>
            <section className="hero is-primary" id="imagem">
          <div className="hero-body">
            <div className="container">
              <p className="title">A nossa Imagem</p>
              <hr style={{ backgroundColor: "white" }} />
              <div className="level">
                <div className="level-left" style={{ flex: "0 0 40%" }}>
                  <div className="content">
                    <p className="title is-5">As Cores</p>
                    <p>
                      O Azul é a nossa cor principal. A cor da nossa Freguesia e
                      a cor do céu que queremos alcançar.
                      <br />O Amarelo forte representa a realeza de Nossa
                      Senhora, as coroas de ouro que pousam sobre Ela e o menino
                      Jesus. Representa também alegria que temos em receber a
                      Nossa Senhora da Nazaré.
                    </p>
                    <br />
                    <p className="title is-5">Os Elementos</p>
                    <p>
                      Neste logótipo podemos ver vários elementos familiares:
                    </p>
                    <ul>
                      <li>
                        O manto dourado de Nossa Senhora, que faz também a forma
                        a colina onde se encontra Santo Estêvão.
                      </li>
                      <li>
                        A figura de Nossa Senhora e do menino Jesus, em branco.
                      </li>
                      <li>As suas coroas douradas</li>
                      <li>
                        Três corações: um em cada coroa, representando o os
                        corações de Maria e Jesus; e um grande ao centro, que
                        simboliza a nossa unidade com Deus, Maria e Jesus.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="level-right">
                  <div className="box">
                    <img src={Logo} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </>
    )
}

export default Imagem
