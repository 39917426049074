import React from "react";

const Contactos = () => {
  return (
    <>
      <section className="hero is-info" id="contactos">
        <div className="hero-body">
          <div className="container">
            <p className="title">Contactos</p>
            <hr />
            <div className="level">
              <div className="level-left" style={{ flex: "1 0 50%" }}>
                <div className="content">
                  <div className="title is-5" style={{ marginBottom: 0 }}>
                    Email
                  </div>
                  <p>cfestas@segnazare.pt</p>
                  <br />
                  <div className="title is-5" style={{ marginBottom: 0 }}>
                    Telefone
                  </div>
                  <p>+351 915 150 115</p>
                  <br />
                  <div className="title is-5" style={{ marginBottom: 0 }}>
                    Localização
                  </div>
                  <p>
                    Rua Padre Luis Germano <br />
                    2665-414 Santo Estevão das Galés, Mafra
                    <br />
                    38.896326, -9.252698
                  </p>
                  <br />
                  <div className="title is-5" style={{ marginBottom: 0 }}>
                    Redes Sociais
                  </div>
                  <a
                    href="https://facebook.com/segnazare"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon" style={{ fontSize: "2rem", margin: '0.5rem 0' }}>
                      <i className="fab fa-facebook-square"></i>
                    </span>
                  </a>
                </div>
              </div>
              <div className="level-right" style={{ flex: "1 0 50%" }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d461.590689928827!2d-9.252446340760839!3d38.89654948685231!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1ed446cb73a947%3A0x18b04ea284cf74bb!2sSanto%20Est%C3%AAv%C3%A3o%20das%20Gal%C3%A9s%2C%202665-414%20Mafra!5e0!3m2!1sen!2spt!4v1632231780275!5m2!1sen!2spt"
                  width="100%"
                  height="300"
                  title="map"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contactos;
