import React from "react";

import igreja from "../assets/img/igreja.jpeg";
import Intersect from "../assets/img/Intersect.png";
import senhora_nova from "../assets/img/senhora_nova.png";

const Landing = () => {
  return (
    <>
      <section
        className="hero is-white is-fullheight-with-navbar"
        style={{ minHeight: "100vh" }}
        id="top"
      >
        <div className="hero-body" id="main-hero">
          <div className="container">
            <div className="level">
              <div className="level-left" style={{ zIndex: 1 }}>
                <div>
                  <p className="title is-size-1-desktop">
                    Nossa Senhora da Nazaré
                  </p>
                  <p className="subtitle is-size-3-desktop">
                    Santo Estêvão das Galés
                    <br />
                    2022 - 2023
                  </p>
                  <br />
                  <a
                    href="https://facebook.com/segnazare"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button is-info"
                  >
                    <strong>Página do Facebook</strong>
                    <span className="icon" style={{ fontSize: "1.3rem" }}>
                      <i className="fab fa-facebook-square"></i>
                    </span>
                  </a>
                </div>
              </div>
              <div className="level-right" style={{ flex: "0 1 30%" }}>
                <img src={senhora_nova} alt="" id="senhora" />
              </div>
            </div>
          </div>
        </div>
        <img src={igreja} id="igreja" alt="" />

        <img src={Intersect} alt="" id="background-object" />
      </section>
    </>
  );
};

export default Landing;
