import React from "react";

import Navbar from "./sections/Navbar";
import Landing from "./sections/Landing";
import Imagem from "./sections/Imagem";
import Contactos from "./sections/Contactos";

const App = () => {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <main>
        <Landing />
        <Imagem />
        <Contactos />
      </main>
    </>
  );
};

export default App;
