import React, { useState } from "react";

import favicon from "../assets/img/favicon.png";

const Navbar = () => {
  const [menuopened, setmenuopened] = useState(false);

  const toggleMenu = (e, value) => {
    if (value) {
      setmenuopened(value);
    } else {
      setmenuopened(!menuopened);
    }
  };

  return (
    <>
      <div className="navbar is-spaced has-shadow is-fixed-top">
        <div className="container">
          <div className="navbar-brand">
            <a href="/" className="navbar-item">
              <img src={favicon} alt="" />
            </a>
            <p
              role="button"
              className={
                menuopened ? "navbar-burger is-active" : "navbar-burger"
              }
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={toggleMenu}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </p>
          </div>
          <div
            className={menuopened ? "navbar-menu is-active" : "navbar-menu"}
            id="navbarBasicExample"
          >
            <div className="navbar-end">
              <a href="#top" className="navbar-item" onClick={(e) => toggleMenu(e, false)}>
                <strong>Início</strong>
              </a>
              <a href="#imagem" className="navbar-item" onClick={(e) => toggleMenu(e, false)}>
                <strong>Imagem</strong>
              </a>
              <a href="#contactos" className="navbar-item" onClick={(e) => toggleMenu(e, false)}>
                <strong>Contactos</strong>
              </a>
              {/* <a href="#" className="navbar-item"><strong>Contactos</strong></a> */}
              {/* <div className="navbar-item">
                <a href="/" className="button is-primary is-rounded">
                  Contactos
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
